<template>
    <div class="quick-info-footer">
        <div class="cell-footer" v-if="data.elementType === 'cell'">
            <ejs-button id="add" cssClass="e-flat" content="Add" :isPrimary="true" v-on:click.native="buttonClickActions"></ejs-button>
        </div>
        <div class="event-footer" v-else>
            <ejs-button id="delete" cssClass="e-flat" content="Delete" v-on:click.native="buttonClickActions"></ejs-button>
            <ejs-button id="more-details" cssClass="e-flat" content="Edit" :isPrimary="true" v-on:click.native="buttonClickActions"></ejs-button>
        </div>
    </div>
</template>

<script>
    // import Vue from "vue";
    // import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";

    // Vue.use(ButtonPlugin);
    export default {
        data() {
            return {
                data: {}
            }
        },
        methods: {
            buttonClickActions: function(e) {
                const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
                const quickPopup = scheduleObj.element.querySelector(".e-quick-popup-wrapper");
                const getSlotData = function() {
                    const clicksObj = quickPopup.querySelector("#clicks").ej2_instances[0];
                    const cellDetails = scheduleObj.getCellDetails(scheduleObj.getSelectedElements());
                    let addObj = {};
                    addObj.Id = scheduleObj.getEventMaxID();
                    addObj.clicks = clicksObj.value;
                    addObj.startTime = new Date(+cellDetails.startTime);
                    addObj.endTime = new Date(+cellDetails.endTime);
                    addObj.generated = false;
                    return addObj;
                };
                if (e.target.id === "add") {
                    const addObj = getSlotData();
                    scheduleObj.addEvent(addObj);
                } else if (e.target.id === "delete") {
                    const eventDetails = scheduleObj.activeEventData.event;
                    let currentAction = "Delete";
                    if (eventDetails.RecurrenceRule) {
                        currentAction = "DeleteOccurrence";
                    }
                    scheduleObj.deleteEvent(eventDetails, currentAction);
                } else {
                    const isCellPopup = quickPopup.firstElementChild.classList.contains("e-cell-popup");
                    const eventDetails = isCellPopup ? getSlotData() : scheduleObj.activeEventData.event;
                    let currentAction = isCellPopup ? "Add" : "Save";
                    if (eventDetails.RecurrenceRule) {
                        currentAction = "EditOccurrence";
                    }
                    scheduleObj.openEditor(eventDetails, currentAction, true);
                }
                scheduleObj.closeQuickInfoPopup();
            }
        }
    }
</script>

<style>
    .cell-footer.e-btn {
        background-color: #ffffff;
        border-color: #878787;
        color: #878787;
    }

    .cell-footer {
        padding-top: 10px;
    }
</style>
