<template>
    <div :id="data.clicks">
        <div class="clicks text-center">
            Clicks: {{ data.clicks }}
        </div>
    </div>
</template>

<script>
    import { Internationalization } from '@syncfusion/ej2-base';
    var instance = new Internationalization();

    export default {
        name: "cell",
        data() {
            return {
                data: {}
            }
        },
        methods: {
            getTimeString: function (value) {
                return instance.formatDate(value, { skeleton: 'hm' });
            }
        }
    }
</script>
