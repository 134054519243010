<template>
    <div>
        <div class="date-text mb-2">{{getDateHeaderText(data.date)}}</div>
        <div v-if="domain">
            <div class="mt-2 mb-2"><span style="font-weight: bold">Total requested:</span> {{ sum }}</div>
            <ejs-progressbutton ref="progressBtn" style="color: white; background-color: #383838;" content="Summary" :duration="10000" :spinSettings="spinSettings" v-on:click.native="loadReport(data)"></ejs-progressbutton>
        </div>
        <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Internationalization } from '@syncfusion/ej2-base';
    import { ProgressButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
    import axios from "axios";
    import {mapMutations, mapState} from "vuex";
    var instance = new Internationalization();
    Vue.use(ProgressButtonPlugin);

    export default {
        name: "cell",
        computed: {
            ...mapState(['domain', 'dateRange']),
            getResults() {
                return this.data.report
            },
            sum() {
                let clicks = 0;
                let dayStart = new Date(new Date(this.data.date).setHours(0, 0, 0, 0));
                let dayEnd = new Date(new Date(this.data.date).setHours(23, 59, 59, 999));
                let schedule = document.querySelector(".e-schedule").ej2_instances[0].eventSettings.dataSource;

                for(let i = 0; i < schedule.length; i++) {
                  let time = new Date(schedule[i].startTime)
                  if(time >= dayStart && time < dayEnd) {
                      clicks += Number(schedule[i].clicks)
                  }
                }
                return clicks
            }
        },
        data() {
            return {
                data: {},
                position: { X: 'Right', Y: 'Bottom' },
                spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
                report: [],
                defaultReport: [
                    {
                        requested: 0,
                        delivered: 0,
                        difference: 0,
                        percent: 0
                    }
                ]
            }
        },
        methods: {
            ...mapMutations(['setReport']),
            getDateHeaderText: function (value) {
                return instance.formatDate(value, { skeleton: 'Ed' });
            },
            loadReport(data) {
                this.report = this.defaultReport;
                let filters = {date: data.date, domain_id: this.domain._id}
                axios.get(`${this.$root.serverUrl}/admin/schedule/report`, {params: {filters}}).then((resp) => {
                    if(resp.data && resp.data.data) {
                        this.setReport([resp.data.data]);
                    } else {
                        this.setReport(this.defaultReport);
                    }
                    window.$('#result').modal('show');
                    this.$refs.progressBtn.progressComplete();
                }).catch((err) => {
                    console.log(err)
                    this.setReport(this.defaultReport);
                    this.$refs.progressBtn.progressComplete();
                    this.$refs.elementRef.show( { title: 'Error !', content: "Communication error, please try again later.", cssClass: 'e-toast-danger' });
                });
            },
        }
    }
</script>

<style>
    .date-text {
        font-size: 17px;
    }
    .e-activecolor {
        color: #00cc00;
    }
    .e-inactivecolor {
        color: #e60000;
    }
    .statustemp.e-activecolor {
        background-color: #ccffcc;
        width: 57px;
    }
    .statustemp.e-inactivecolor {
        background-color: #ffd7cc;
        width: 57px;
    }
</style>
