<template>
    <div class="quick-info-content">
        <div class="e-cell-content" v-if="data.elementType === 'cell'">
            <div class="content-area">
                <ejs-textbox ref="clicksObj" id="clicks" placeholder="Clicks"></ejs-textbox>
            </div>
        </div>
        <div class="event-content" v-else>
            <div class="meeting-type-wrap">
                <label>Clicks</label>:<span>{{data.clicks}}</span>
            </div>
            <div class="meeting-type-wrap">
                <label>Start Time</label>:<span v-if="user">{{usertTImeZone(data.startTime,user.timezone)}}</span>
            </div>
            <div class="meeting-subject-wrap">
                <label>End Time</label>:<span v-if="user">{{usertTImeZone(data.startTime,user.timezone)}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import moment from "moment-timezone";
    import axios from 'axios'
    import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
    Vue.use(TextBoxPlugin);

    export default {
        data() {
            return {
                data: {},
                user: '',
            }
        },
        created(){ 
            this.getUser()
        },
        methods: {
             async getUser() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/profile/`)
            if (resp.data && resp.data.error) {
                console.log(1)
            } else {
                this.user  = resp.data.data
            }
        },
        usertTImeZone:function (time, timezone) {
        if(timezone == undefined){ //if no timezone from user
        return time
        }
        let data = moment(time);
        return data.tz(timezone).format('YYYY-MM-DD hh:mm A');
        }
        
         }
    }
</script>

<style>
    .content-area {
        margin: 0;
        padding: 10px;
        width: auto;
    }
    .event-content {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15px;
    }

    .meeting-type-wrap,
    .meeting-subject-wrap,
    .notes-wrap {
        font-size: 11px;
        color: #666;
        letter-spacing: 0.33px;
        height: 24px;
        padding: 5px;
    }
    .event-content div label {
        display: inline-block;
        min-width: 45px;
        color: #666;
    }

    .event-content div span {
        font-size: 11px;
        color: #151515;
        letter-spacing: 0.33px;
        line-height: 14px;
        padding-left: 8px;
    }
</style>
