<template>
    <table class="custom-event-editor" width="100%" cellpadding="1">
        <tbody>
        <tr>
            <td class="e-textlabel">Clicks</td>
            <td colspan="4">
                <ejs-textbox ref="clicksObj" id="clicks" class="e-field" name="clicks"></ejs-textbox>
            </td>
        </tr>
        <tr>
            <td class="e-textlabel"> Redistribute</td>
            <td colspan="4">
                <ejs-checkbox :change="RedistributeClicks" :checked="false" ref="redistributeObj" id="redistribute" name="redistribute" label='Total number of clicks will not be affected. Remaining clicks will be redistributed to compensate addition or removal of clicks'></ejs-checkbox>
            </td>
        </tr>
        <tr>
            <td class="e-textlabel"> Lock</td>
            <td colspan="4">
                <ejs-checkbox :change="LockClicks" :checked="data.generated"  label=''></ejs-checkbox>
            </td>
        </tr>
        <tr style="display: none;">
            <td class="e-textlabel">From</td>
            <td colspan="4">
                <ejs-datetimepicker id="startTime" class="e-field" name="startTime"></ejs-datetimepicker>
            </td>
        </tr>
        <tr style="display: none;">
            <td class="e-textlabel">To</td>
            <td colspan="4">
                <ejs-datetimepicker id="endTime" class="e-field" name="endTime" ></ejs-datetimepicker>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import Vue from "vue";
    import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
    import { CheckBoxPlugin} from "@syncfusion/ej2-vue-buttons";
    import ScheduleBus from '../../busses/scheduleBus';

    Vue.use(TextBoxPlugin);
    Vue.use(CheckBoxPlugin);
    Vue.use(DateTimePickerPlugin);
    export default {
        mounted() {
            ScheduleBus.$emit('defaultEditorValues', {
                lock: this.data.generated,
                redistribute: false
            })
        },
        data() {
            return {
                eventData:  ['New', 'Requested', 'Confirmed'],
                data: {}
            }
        },
        methods:{

            RedistributeClicks: function(e){
                ScheduleBus.$emit('redistributeChange',{
                    value: e.checked
                });
            },
            LockClicks: function(e){
                ScheduleBus.$emit('lockChange',{
                    value: e.checked
                });
            }
        }
    }
</script>
