<template>
    <div class="quick-info-header">
        <div class="quick-info-header-content" v-if="data.elementType == 'cell'" :style="{'align-items':'center','color':'#919191'}">
            <div class="quick-info-title">{{getHeaderTitle(data)}}</div>
            <div class="duration-text">{{getHeaderDetails(data)}}</div>
        </div>
        <div class="quick-info-header-content" v-else :style="{'color':'#000000'}">
            <div class="quick-info-title">{{getHeaderTitle(data)}}</div>
            <div class="duration-text">{{getHeaderDetails(data)}}</div>
        </div>
    </div>
</template>

<script>
    import { Internationalization } from "@syncfusion/ej2-base";

    export default {
        data() {
            return {
                intl: new Internationalization(),
                data: {}
            }
        },
        methods: {
            getHeaderTitle: function(data) {
                return data.elementType === "cell" ? "Add Hourly Clicks" : "Hourly Clicks Details";
            },
            getHeaderDetails: function(data) {
                return (
                    this.intl.formatDate(data.startTime, { type: "date", skeleton: "full" }) + " (" +
                    this.intl.formatDate(data.startTime, { skeleton: 'hm' }) + " - " +
                    this.intl.formatDate(data.endTime, { skeleton: 'hm' }) + ")"
                );
            }
        }
    }
</script>

<style>
    .quick-info-header {
        background-color: white;
        padding: 8px 18px;
    }
    .quick-info-header-content {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        padding: 5px 10px 5px;
    }
    .quick-info-title {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.48px;
        height: 22px;
    }
    .duration-text {
        font-size: 11px;
        letter-spacing: 0.33px;
        height: 14px;
    }
</style>
