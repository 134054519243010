<template>
    <div>
        <div id="target">
            <div class="click-calendar mb-3">
                <div class="schedule-container">
                    <div class="title-container d-flex justify-content-between">
                        <h2 class="title-text border-bottom pb-2"><i
                            class="fa fa-calendar mr-3"></i>{{ domain ? domain.domain : '' }} Clicks Schedule</h2>
                        <div class="form-group d-flex"><span style="font-weight: bold;">Time Zone: </span> {{
                                timezone
                            }}
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-12 d-flex">
                            <div class="form-group">
                                <label>Domain</label>
                                <select v-model="domain_id" class="form-control text-capitalize require"
                                        @change="changeDomain">
                                    <option value="" disabled>-- Select Domain --</option>
                                    <option v-for="(domain, index) in domains" :value="domain._id" :key="index">
                                        {{ domain._id }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <ejs-schedule id='Schedule' ref="ScheduleObj" height="100%" cssClass='schedule-drag-drop'
                                  :selectedDate='selectedDate' :eventSettings='eventSettings'
                                  :quickInfoTemplates="quickInfoTemplates" :editorTemplate='editorTemplate'
                                  :dateHeaderTemplate="DateTemplate2" :eventRendered="onEventRendered"
                                  :navigating="onNavigating" :timeScale="timeScale" :popupOpen="onPopupOpen"
                                  :actionComplete="onActionComplete" :actionBegin="onActionBegin">
                        <e-views>
                            <e-view option="Day" :eventTemplate="cellTemplate"></e-view>
                            <e-view option="Week" :eventTemplate="cellTemplate"></e-view>
                            <e-view option="Month" :eventTemplate="cellTemplate"></e-view>
                        </e-views>
                    </ejs-schedule>

                </div>
            </div>
            <div class="modal" id="result" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Click Summary</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <table class="table table-hover bg-light text-dark">
                                    <thead>
                                    <tr>
                                        <td>Requested</td>
                                        <td>Delivered</td>
                                        <td>Difference</td>
                                        <td>Percent Difference</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(data, index) in report" :key="index">
                                        <td>
                                            <div
                                                :class="{'statustemp': true, 'e-activecolor': data.requested > 0, 'e-inactivecolor': data.requested < 0}">
                                                <span
                                                    :class="{'e-activecolor': data.requested > 0, 'e-inactivecolor': data.requested < 0}">{{ data.requested }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                :class="{'statustemp': true, 'e-activecolor': data.delivered > 0, 'e-inactivecolor': data.delivered < 0}">
                                                <span
                                                    :class="{'e-activecolor': data.delivered > 0, 'e-inactivecolor': data.delivered < 0}">{{ data.delivered }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                :class="{'statustemp': true, 'e-activecolor': data.difference > 0, 'e-inactivecolor': data.difference < 0}">
                                                <span
                                                    :class="{'e-activecolor': data.difference > 0, 'e-inactivecolor': data.difference < 0}">{{ data.difference }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                :class="{'statustemp': true, 'e-activecolor': data.percent > 0, 'e-inactivecolor': data.percent < 0}">
                                                <span
                                                    :class="{'e-activecolor': data.percent > 0, 'e-inactivecolor': data.percent < 0}">{{ data.percent }}%</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-dialog v-show="showModal" ref="modalDialog" :isModal="true" :header='header' :target='target'
                        :width='width' :buttons='buttons' :content='content' :position="dlgPosition"></ejs-dialog>
            <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import moment from 'moment';
import {SchedulePlugin, Day, Week, Month, DragAndDrop} from "@syncfusion/ej2-vue-schedule";
import {createSpinner, showSpinner, hideSpinner} from '@syncfusion/ej2-popups';
import {DialogPlugin} from '@syncfusion/ej2-vue-popups';
import {ToastPlugin} from "@syncfusion/ej2-vue-notifications";
import celltemplate from "../components/schedule/cell.vue";
import emptytemplate from "../components/schedule/emptyCell.vue";
import editortemplate from "../components/schedule/editor.vue";
import dateHeaderTemplate from "../components/schedule/header.vue";
import {ProgressButtonPlugin} from "@syncfusion/ej2-vue-splitbuttons";
import {mapMutations, mapState} from 'vuex';
import headerTemplateVue from "../components/schedule/quickInfo/header.vue";
import contentTemplateVue from "../components/schedule/quickInfo/content.vue";
import footerTemplateVue from "../components/schedule/quickInfo/footer.vue";
import axios from "axios";
import store from "./../store";
import {Internationalization} from '@syncfusion/ej2-base';
import ScheduleBus from "../busses/scheduleBus";
//  import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";

    import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
    import { CheckBoxPlugin} from "@syncfusion/ej2-vue-buttons";


    Vue.use(TextBoxPlugin);
    Vue.use(CheckBoxPlugin);
    Vue.use(DateTimePickerPlugin);

var instance = new Internationalization();

// Vue.use(ButtonPlugin);
Vue.use(SchedulePlugin);
Vue.use(ToastPlugin);
Vue.use(DialogPlugin);
Vue.use(ProgressButtonPlugin);

export default {
    name: "Click.Schedule",
    store: store,
    provide: {
        schedule: [Day, Week, Month, DragAndDrop]
    },
    created() {
        this.loadDomains();
        ScheduleBus.$on("redistributeChange",function(e){
            this.updateRedistribute(e.value);
        }.bind(this));

        ScheduleBus.$on("lockChange", function(e){
            this.updateLock(e.value);
        }.bind(this))

        ScheduleBus.$on('defaultEditorValues', function(e){
            this.updateDefaults(e);
        }.bind(this));
        // this.dcontent = this.domain
    },
    data: function () {
        return {
            showModal: false,
            dialogOpen: true,
            limitWarning: false,
            dlgPosition: {X: 'center', Y: 'center'},
            target: "#target",
            width: '335px',
            header: 'Daily limit exceeded',
            content: 'You have exceeded your daily limit, additional charges will be applied for additional clicks.',
            buttons: [{click: this.dlgButtonClick, buttonModel: {content: 'OK', isPrimary: true}}],
            defaultTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timezones: moment.tz.names(),
            manual: false,
            result: null,
            route: '',
            dcontent: '',
            loaded: false,
            domains: [],
            domain_id: null,
            scheduleObj: null,
            position: {X: 'Right', Y: 'Bottom'},
            timeScale: {enable: true, interval: 60, slotCount: 1},
            eventSettings: {
                dataSource: [],
                fields: {
                    clicks: {name: 'clicks', validation: {required: true, regex: ['^[0-9]', 'Please enter a number']}},
                    startTime: {name: 'startTime', validation: {required: true}},
                    endTime: {name: 'endTime', validation: {required: true}}
                }
            },
            editorPage :{
                redistribute: false,
                lock:false
            },
            DateTemplate2: function () {
                return {
                    template: Vue.component("Content", {
                        template: `
                            <div>
                            <div class="date-text mb-2">{{ getDateHeaderText(data.date) }}</div>
                            <div v-if='domain'>
                                <div class="mt-2 mb-2"><span style="font-weight: bold">Total requested:</span> {{ sum }}
                                </div>
                                <ejs-progressbutton ref="progressBtn" style="color: white; background-color: #383838;"
                                                    content="Summary" :duration="10000" :spinSettings="spinSettings"
                                                    v-on:click.native="loadReport(data)"></ejs-progressbutton>
                            </div>
                            <ejs-toast ref='elementRef' id='element' title='' content=''
                                       :position='position'></ejs-toast>
                            </div>`,
                        store: store,
                        computed: {
                            ...mapState(['domain', 'dateRange']),
                            getResults() {

                                return this.data.report
                            },
                            sum() {

                                let clicks = 0;
                                let dayStart = new Date(new Date(this.data.date).setHours(0, 0, 0, 0));
                                let dayEnd = new Date(new Date(this.data.date).setHours(23, 59, 59, 999));
                                let schedule = document.querySelector(".e-schedule").ej2_instances[0].eventSettings.dataSource;

                                for (let i = 0; i < schedule.length; i++) {
                                    let time = new Date(schedule[i].startTime)
                                    if (time >= dayStart && time < dayEnd) {
                                        clicks += Number(schedule[i].clicks)
                                    }
                                }
                                return clicks
                            }
                        },
                        data() {
                            return {
                                data: {},
                                position: {X: 'Right', Y: 'Bottom'},
                                spinSettings: {
                                    duration: 10000000,
                                    position: 'Right',
                                    width: 20,
                                    template: '<div class="template"></div>'
                                },
                                report: [],
                                defaultReport: [
                                    {
                                        requested: 0,
                                        delivered: 0,
                                        difference: 0,
                                        percent: 0
                                    }
                                ]
                            }
                        },
                        methods: {
                            ...mapMutations(['setReport']),
                            getDateHeaderText(value) {
                                return instance.formatDate(value, {skeleton: 'Ed'});
                            },
                            loadReport(data) {
                                this.report = this.defaultReport;
                                let filters = {date: data.date, domain_id: this.domain._id}
                                axios.get(`${this.$root.serverUrl}/admin/schedule/report`, {params: {filters}}).then((resp) => {
                                    if (resp.data && resp.data.data) {
                                        this.setReport([resp.data.data]);
                                    } else {
                                        this.setReport(this.defaultReport);
                                    }
                                    window.$('#result').modal('show');
                                    this.$refs.progressBtn.progressComplete();
                                }).catch((err) => {
                                    console.log(err)
                                    this.setReport(this.defaultReport);
                                    this.$refs.progressBtn.progressComplete();
                                    this.$refs.elementRef.show({
                                        title: 'Error !',
                                        content: "Communication error, please try again later.",
                                        cssClass: 'e-toast-danger'
                                    });
                                });
                            },
                        }
                    })
                };
            },
            dateHeaderTemplate: function () {
                return {template: {parent: this, extends: dateHeaderTemplate, propsData: {domain: this.dcontent}}}
            },
            cellTemplate: function () {
                return {template: celltemplate}
            },
            emptyTemplate: function () {
                return {template: emptytemplate}
            },
            editorTemplat: function () {
                return {template: editortemplate}
            },
            editorTemplate:function () {
                   return {
                    template: Vue.component("Content", {
                        template: `
        <table class="custom-event-editor p-5" width="100%" cellpadding="1">
        <tbody>
        <tr>
            <td class="e-textlabel">Clicks</td>
            <td colspan="4" class="pb-2">
                <ejs-textbox ref="clicksObj" id="clicks" class="e-field" name="clicks"></ejs-textbox>
            </td>
        </tr>
        <tr>
            <td class="e-textlabel"> Redistribute</td>
            <td colspan="6" class="p-2 ">
                <ejs-checkbox :change="RedistributeClicks" :checked="false" ref="redistributeObj" id="redistribute" name="redistribute" label='Total number of clicks will not be affected. Remaining clicks will be redistributed to compensate addition or removal of clicks'></ejs-checkbox>
            </td>
        </tr>
        <tr>
            <td class="e-textlabel"> Lock</td>
            <td colspan="6" class="p-2">
                <ejs-checkbox :change="LockClicks" :checked="data.generated"  label='Locking this time will prevent changes to clicks under this time for the remaining days'></ejs-checkbox>
            </td>
        </tr>
        <tr style="display: none;">
            <td class="e-textlabel">From</td>
            <td colspan="4">
                <ejs-datetimepicker id="startTime" class="e-field" name="startTime"></ejs-datetimepicker>
            </td>
        </tr>
        <tr style="display: none;">
            <td class="e-textlabel">To</td>
            <td colspan="6">
                <ejs-datetimepicker id="endTime" class="e-field" name="endTime" ></ejs-datetimepicker>
            </td>
        </tr>
        </tbody>
    </table>
                        `,
                        store: store,
                   mounted() {
            ScheduleBus.$emit('defaultEditorValues', {
                lock: this.data.generated,
                redistribute: false
            })
        },
        data() {
            return {
                eventData:  ['New', 'Requested', 'Confirmed'],
                data: {}
            }
        },
        methods:{

            RedistributeClicks: function(e){
                ScheduleBus.$emit('redistributeChange',{
                    value: e.checked
                });
            },
            LockClicks: function(e){
                ScheduleBus.$emit('lockChange',{
                    value: e.checked
                });
            }
        }      
                       
                
                    })
                };
            },
            quickInfoTemplates: {
                header: function () {
                    return {template: headerTemplateVue};
                },
                content: function () {
                    return {template: contentTemplateVue};
                },
                footr: function () {
                    return {template: footerTemplateVue};
                },
                footer:function () {
                     return {
                    template: Vue.component("Content", {
                        template: `
                            <div class="quick-info-footer">
                            <div class="cell-footer" v-if="data.elementType === 'cell'">
                                <ejs-button id="add" cssClass="e-flat" content="Add" :isPrimary="true" v-on:click.native="buttonClickActions"></ejs-button>
                            </div>
                            <div class="event-footer" v-else>
                                <ejs-button id="delete" cssClass="e-flat" content="Delete" v-on:click.native="buttonClickActions"></ejs-button>
                                <ejs-button id="more-details" cssClass="e-flat" content="Edit" :isPrimary="true" v-on:click.native="buttonClickActions"></ejs-button>
                            </div>
                        </div>`,
                        store: store,
                       
                        data() {
                            return {
                                data: {},
                                
                            }
                        },
                        methods: {
                           buttonClickActions: function(e) {
                const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
                const quickPopup = scheduleObj.element.querySelector(".e-quick-popup-wrapper");
                const getSlotData = function() {
                    const clicksObj = quickPopup.querySelector("#clicks").ej2_instances[0];
                    const cellDetails = scheduleObj.getCellDetails(scheduleObj.getSelectedElements());
                    let addObj = {};
                    addObj.Id = scheduleObj.getEventMaxID();
                    addObj.clicks = clicksObj.value;
                    addObj.startTime = new Date(+cellDetails.startTime);
                    addObj.endTime = new Date(+cellDetails.endTime);
                    addObj.generated = false;
                    return addObj;
                };
                if (e.target.id === "add") {
                    const addObj = getSlotData();
                    scheduleObj.addEvent(addObj);
                } else if (e.target.id === "delete") {
                    const eventDetails = scheduleObj.activeEventData.event;
                    let currentAction = "Delete";
                    if (eventDetails.RecurrenceRule) {
                        currentAction = "DeleteOccurrence";
                    }
                    scheduleObj.deleteEvent(eventDetails, currentAction);
                } else {
                    const isCellPopup = quickPopup.firstElementChild.classList.contains("e-cell-popup");
                    const eventDetails = isCellPopup ? getSlotData() : scheduleObj.activeEventData.event;
                    let currentAction = isCellPopup ? "Add" : "Save";
                    if (eventDetails.RecurrenceRule) {
                        currentAction = "EditOccurrence";
                    }
                    scheduleObj.openEditor(eventDetails, currentAction, true);
                }
                scheduleObj.closeQuickInfoPopup();
            }
                          
                        }
                    })
                };
                }
            },
            selectedDate: new Date(),
            currentView: 'Week',
            dateRange: {}
        }
    },
    mounted() {
        this.route = this.$route.query.id
        if (this.domain) this.loaded = true
        if (this.domain) this.domain_id = this.domain._id;
        this.$refs.modalDialog.hide();
        this.scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
        this.dateRange = {
            start: moment().startOf(this.currentView.toLowerCase()),
            end: moment().endOf(this.currentView.toLowerCase())
        };
        this.setDateRange(this.dateRange)
        if (this.domain) this.loadRecord();
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('Schedule')
        });
        setTimeout(() => {
            this.showModal = true;
        }, 4000)
    },

    computed: {
        ...mapState(['domain', 'report']),
        timezone() {
            return this.domain ? this.domain.client ? this.domain.client.TimeZone : this.defaultTimezone : this.defaultTimezone
        },
    },
    methods: {
        ...mapMutations(['setDomain', 'setDomainConfig', 'setDateRange']),

        showLoader: function () {
            showSpinner(document.getElementById('Schedule'));
        },
        hideLoader: function () {
            hideSpinner(document.getElementById('Schedule'));
        },
        modalBtnClick: function () {
            this.$refs.modalDialog.show();
        },
        dlgButtonClick: function () {
            this.$refs.modalDialog.hide();
        },
        checkLimit(date) {
            let clicks = 0;
            let dayStart = new Date(new Date(date).setHours(0, 0, 0, 0));
            let dayEnd = new Date(new Date(date).setHours(23, 59, 59, 999));
            let schedule = document.querySelector(".e-schedule").ej2_instances[0].eventSettings.dataSource;

            for (let i = 0; i < schedule.length; i++) {
                let time = new Date(schedule[i].startTime)
                if (time >= dayStart && time < dayEnd) {
                    clicks += Number(schedule[i].clicks)
                }
            }

            if (clicks > this.domain.dailyLimit) {
                this.modalBtnClick()
            }
        },
        timeToNumber(time) {
            let hm = time.split(":");
            let hours = Number(hm[0]);
            return hours * 100 + Number(hm[1]);
        },
        onEventRendered: function (args) {
            console.log({type: 'eventRendered', args });
            if (!args.element) {
                return;
            }

            if (args.data && args.data.generated) {
                args.element.style.backgroundColor = "transparent";
                args.element.style.color = "#000000";
            }
        },
        active(date) {
            if (!this.domain || !this.domain.startTime || !this.domain.endTime) return true
            let now = this.timeToNumber(moment(date).startOf('hour').format('HH:mm'));
            let startTime = this.timeToNumber(this.domain.startTime);
            let endTime = this.timeToNumber(this.domain.endTime);

            return !((now < startTime) && (now >= endTime));
        },
        changeDomain() {
            this.scheduleObj.eventSettings.dataSource = [];
            let domain = this.domains.filter((domain) => {
                return domain._id === this.domain_id;
            })
            this.setDomain(domain[0]);
            this.loadRecord();
        },
        getConfig() {
            if (!this.domain.config_id) return
            let filters = {domain_id: this.domain._id};
            axios.get(`${this.$root.serverUrl}/admin/domain/config`, {
                params: {
                    date: this.dateRange,
                    filters
                }
            }).then((resp) => {
                if (resp.data && resp.data.data) {
                    this.config = resp.data.data;
                    this.setDomainConfig(this.config);
                    this.scheduleObj.refresh();
                } else {
                    this.$router.push('/404')
                }
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                if (err.response.status != '401') {
                    this.$router.push('/500')
                    this.$root.preloader = false;
                }
            });
        },
        loadDomains() {
            let request = {filters: {}}
            if (this.domain && this.domain.product_id) request.filters.product_id = this.domain.product_id;
            axios.get(`${this.$root.serverUrl}/admin/domain`, {params: request}).then((resp) => {
                if (resp.data && resp.data.data) {
                    this.domains = resp.data.data;
                } else {
                    this.$router.push('/404')
                }
                this.$root.preloader = false;
            }).catch((err) => {
                if (err.response.status != '401') {
                    this.$router.push('/500')
                    this.$root.preloader = false;
                }
            });
        },
        onPopupOpen: function (args) {
            console.log({type: 'popOpen', args})
            if (args.type === 'QuickInfo' || args.type === 'Editor') {
                args.cancel = this.onEventCheck(args);
            }
        },
        onActionBegin(args) {
            console.log({type: 'actionBegin', args });
            if ((args.requestType === 'eventCreate') || args.requestType === 'eventChange' || args.requestType === 'eventRemove') {
                args.cancel = this.onEventCheck(args);

                if (args.requestType === 'eventCreate') {
                    if (!args.data[0].clicks) {
                        this.$refs.elementRef.show({
                            title: 'Error !',
                            content: 'Clicks cannot be empty',
                            cssClass: 'e-toast-danger'
                        });
                        args.cancel = true;
                    }

                    if (!String(args.data[0].clicks).match('^[0-9]')) {
                        this.$refs.elementRef.show({
                            title: 'Error !',
                            content: 'Clicks must be a valid number',
                            cssClass: 'e-toast-danger'
                        });
                        args.cancel = true;
                    }
                } else if (args.requestType === 'eventChange') {
                    if (!args.data.clicks) {
                        this.$refs.elementRef.show({
                            title: 'Error !',
                            content: 'Clicks cannot be empty',
                            cssClass: 'e-toast-danger'
                        });
                        args.cancel = true;
                    }

                    if (!String(args.data.clicks).match('^[0-9]')) {
                        this.$refs.elementRef.show({
                            title: 'Error !',
                            content: 'Clicks must be a valid number',
                            cssClass: 'e-toast-danger'
                        });
                        args.cancel = true;
                    }
                }

            }

            if (args.requestType === 'eventChange') this.old = this.scheduleObj.activeEventData.event;
        },
        onActionComplete: function (e) {
            console.log({type: 'actionComplete', e });
            if (this.manual) return this.manual = false;
            if (e.requestType === "eventCreated") {
                this.addRecord(e.addedRecords[0]);
            } else if (e.requestType === "eventRemoved") {
                this.deleteRecord(e.deletedRecords[0]);
            } else if (e.requestType === "eventChanged") {
                this.updateRecord(e.changedRecords[0]);
            }
        },
        onEventCheck(args) {
            console.log({type: 'eventCheck', args });
            return false;

            /*if (args.data) {
                if ((args.data.endTime - args.data.startTime) > 3600000) return true;
                if (!this.active(args.data.startTime)) return true
            }
            let eventObj = args.data instanceof Array ? args.data[0] : args.data;
            return (eventObj.startTime < new Date());*/
        },
        onNavigating: function (e) {
            console.log(e)
            if (e.action === 'view') {
                this.currentView = e.currentView
                console.log(this.currentView)
            }

            if (e.action === 'date') {
                this.dateRange = {
                    start: moment(e.currentDate).startOf(this.currentView.toLowerCase()),
                    end: moment(e.currentDate).endOf(this.currentView.toLowerCase())
                }
                this.setDateRange(this.dateRange)
                this.loadRecord();
            } else if (e.action === 'view') {
                this.dateRange = {
                    start: moment(this.dateRange.start).startOf(this.currentView.toLowerCase()),
                    end: moment(this.dateRange.end).endOf(this.currentView.toLowerCase())
                }
                this.setDateRange(this.dateRange)
                this.loadRecord();
            }
        },
        loadRecord() {

            let filters = {};
            if (this.domain_id) filters.domain_id = this.domain_id;
            axios.get(`${this.$root.serverUrl}/admin/schedule`, {
                params: {
                    date: this.dateRange,
                    filters
                }
            }).then((resp) => {
                //Process the results
                this.$root.preloader = false
                if (resp.data && !resp.data.error) {

                    this.scheduleObj.eventSettings.dataSource = resp.data.data;
                    this.dateHeaderTemplate
                    //  this.scheduleObj.dateHeaderTemplate.refresh();

                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false
                    this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                }
                //this.hideLoader();
            }).catch((err) => {
                this.errormsg = err.message || "An unexpected error occoured"
                this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
            })
        },
        updateRecord(data) {
            data.redistribute = this.editorPage.redistribute;
            data.generated = this.editorPage.lock;
            console.log({data})
            axios({
                url: `${this.$root.serverUrl}/admin/schedule/${data._id}`,
                data: data,
                responseType: 'json',
                method: 'PUT'
            }).then((resp) => {
                //Process the results
                this.$root.preloader = false
                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Your changes were saved successfully',
                        cssClass: 'e-toast-success'
                    });
                    this.loadRecord();
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false
                    this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                    this.$root.preloader = false
                    this.manual = true;
                    this.scheduleObj.saveEvent(this.old);
                }
                this.countClicks();
            }).catch((err) => {
                console.log(err)
                this.$root.preloader = false
                this.errormsg = err.message || "An unexpected error occoured"
                this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                this.manual = true;
                this.scheduleObj.saveEvent(this.old);
            })
        },
        countClicks() {
            axios.get(`${this.$root.serverUrl}/admin/schedule/count/${this.domain.product_id}`, {}).then((resp) => {
                if (resp.data && resp.data.total) {
                    if (resp.data.total > this.domain.NumberOfClicks && !this.limitWarning) {
                        this.modalBtnClick();
                        this.limitWarning = true;
                    }
                }
            })
        },
        deleteRecord(data) {
            axios({
                url: `${this.$root.serverUrl}/admin/schedule/${data._id}`,
                data: data,
                responseType: 'json',
                method: 'DELETE'
            }).then((resp) => {
                //Process the results
                this.$root.preloader = false
                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Your changes were saved successfully',
                        cssClass: 'e-toast-success'
                    });
                    this.loadRecord();
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false
                    this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                    this.$root.preloader = false
                    this.manual = true;
                    this.scheduleObj.addEvent(data);
                }
            }).catch((err) => {
                this.$root.preloader = false
                this.errormsg = err.message || "An unexpected error occoured"
                this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                this.manual = true;
                this.scheduleObj.addEvent(data);
            })
        },
        addRecord(data) {
            data.domain_id = this.domain._id;
            data.client_id = this.domain.client_id;
            axios({
                url: `${this.$root.serverUrl}/admin/schedule`,
                data: data,
                responseType: 'json',
                method: 'POST'
            }).then((resp) => {
                //Process the results
                this.$root.preloader = false
                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Your changes were saved successfully',
                        cssClass: 'e-toast-success'
                    });
                    this.loadRecord();
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false
                    this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                    this.$root.preloader = false
                    this.manual = true;
                    this.scheduleObj.deleteEvent(data, 'Delete');
                }
                this.countClicks();
            }).catch((err) => {
                this.$root.preloader = false
                this.errormsg = err.message || "An unexpected error occoured"
                this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
                this.manual = true;
                this.scheduleObj.deleteEvent(data, 'Delete');
            })
        },
        updateRedistribute(value){
            console.log({value})
            this.editorPage.redistribute = value
        },
        updateLock(value){
            console.log({value})
            this.editorPage.lock = value;
        },
        updateDefaults(value){
            console.log({value});
            this.editorPage.redistribute = value.redistribute;
            this.editorPage.lock = value.lock;
        }
    }
}
</script>

<style>
.control-section {
    height: 100%;
    min-height: 200px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
    padding: 0 14px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
    display: block;
}

.e-quick-popup-wrapper .e-popup-footer button:first-child {
    margin-right: 5px;
}

.e-more-popup-wrapper .e-appointment {
    height: 48px;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    width: 100% !important;
}

.e-dlg-center-center {
    position: fixed !important;
}
</style>
