<template>
    <div>
        <div v-if="active">
            <div v-if="data.type !== 'alldayCells' && domain" class="clicks">
                Clicks: {{ getClicks }}
            </div>
        </div>
        <div v-else style="background: darkred;height: 33px;opacity: 0.6;"></div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import { mapState } from 'vuex';

    export default {
        name: "cell",
        data() {
            return {
                data: {},
            }
        },
        computed: {
            ...mapState(['domain', 'dateRange']),
            getClicks() {
                let clicks = 0;
                if (!this.domain || !this.domain.config || !this.domain.startTime || !this.domain.endTime) return clicks

                if(new Date(this.data.date).getTime() >= new Date(this.dateRange.start).getTime() && new Date(this.data.date).getTime() < new Date(this.dateRange.end).getTime()) {
                    for(let i = 0; i < this.domain.config.length; i++) {
                        let config = this.domain.config[i];
                        if(new Date(config.startTime).getTime() == new Date(this.data.date).getTime()) {
                            clicks = config.clicks
                            break;
                        }
                    }
                }
                return clicks;
            },
            active() {
                if (!this.domain || !this.domain.startTime || !this.domain.endTime) return true
                let now = this.timeToNumber(moment(this.data.date).startOf('hour').format('HH:mm'));
                let startTime = this.timeToNumber(this.domain.startTime);
                let endTime = this.timeToNumber(this.domain.endTime);

                return !((now < startTime) && (now >= endTime));
            },
            defaultClicks() {
                let clicks = 0;

                if (!this.domain || !this.domain.startTime || !this.domain.endTime) return clicks

                let startTime = moment(this.domain.startTime, 'HH:mm').unix();
                let endTime = moment(this.domain.endTime, 'HH:mm').unix();

                let startDate =  moment(this.domain.startDate).startOf('day').unix();
                let endDate = moment(this.domain.endDate).endOf('day').unix();

                let hour = moment.tz(this.domain.client ? this.domain.client.timezone : "").hours();
                if(startTime > endTime) endTime = moment(this.domain.endTime, 'HH:mm').add(1, "days").unix();

                let runningDays = Math.ceil((endDate - startDate) / 86400);
                let dailyClicks = (this.domain.product.clicks * (this.domain.clickPercent / 100)) / runningDays;

                console.log("################", dailyClicks)

                let hourPercentages = {
                    1: 0.0416,
                    2: 0.0416,
                    4: 0.0416,
                    5: 0.0416,
                    6: 0.0416,
                    7: 0.0416,
                    8: 0.0416,
                    9: 0.0416,
                    10: 0.0416,
                    11: 0.0416,
                    12: 0.0416,
                    13: 0.0416,
                    14: 0.0416,
                    15: 0.0416,
                    16: 0.0416,
                    17: 0.0416,
                    18: 0.0416,
                    19: 0.0416,
                    20: 0.0416,
                    21: 0.0416,
                    22: 0.0416,
                    23: 0.0416,
                    24: 0.0416,
                };

                let percent = hourPercentages[hour];
                clicks = dailyClicks * percent;

                console.log(clicks)


                return Math.ceil(clicks);
            }
        },
        methods: {
            timeToNumber(time) {
                let hm = time.split(":");
                let hours = Number(hm[0]);
                return hours * 100 + Number(hm[1]);
            }
        }
    }
</script>
